<template>
    <div
        class="px-6 py-5 border border-dashed flex space-x-4"
        :class="isFull ? '' : 'max-w-lg'"
    >
        <Icon
            :name="icon"
            size="7"
            color="gray-600"
            custom-class="flex-shrink-0"
        />
        <div class="mt-1">
            <Text
                size=""
                weight="bold"
                v-if="title"
                :content="title"
                color="gray-600"
            />
            <Text
                size="sm"
                :content="description"
                color="gray-500"
                :customClass="title && 'mt-2'"
            />
            <ButtonWithIcon
                is-leading
                :click="() => action()"
                :label="btnText"
                iconName="PlusIcon"
                variant="success"
                size="md"
                customClass="mt-7"
                :isLoading="isLoading"
                v-if="btnText"
            />
            <!-- <Button variant="success" :content="btnText" :click="() => action()" customClass="mt-7"/> -->
        </div>
    </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Button from "../../../atoms/Button/Button.vue";
import ButtonWithIcon from "../../../molecules/Button/WithIcon/WithIcon.vue";
import Text from "../../../atoms/Text/Text.vue";
/**
 * - Use it to show a simple modal.
 */
export default {
    components: {
        Icon,
        Button,
        Text,
        ButtonWithIcon,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        btnText: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: "",
        },
        action: {
            type: Function,
            default: () => {},
        },
        isFull: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
};
</script>
